import React from 'react';

const IconReport = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enable-background="new 0 0 1000 1000">
    <g>
      <path d="M832.7,990H167.3c-40.2,0-72.9-32.7-72.9-72.8V82.9c0-40.2,32.7-72.9,72.9-72.9h518.9l219.4,229.3v677.8C905.6,957.3,872.9,990,832.7,990z M167.3,58.1c-13.7,0-24.8,11.1-24.8,24.8v834.3c0,13.6,11.1,24.8,24.8,24.8h665.4c13.7,0,24.8-11.1,24.8-24.8V258.6L665.6,58.1H167.3z" />
      <path d="M881.5,273H724.7c-40.2,0-72.9-32.7-72.9-72.8V34c0-9.8,6-18.7,15.1-22.3c9.1-3.6,19.5-1.4,26.3,5.7l205.6,214.9c6.7,7,8.5,17.2,4.7,26.1C899.8,267.3,891.1,273,881.5,273z M699.9,93.9v106.2c0,13.7,11.1,24.8,24.8,24.8h100.5L699.9,93.9z" />
      <path d="M819.7,589c-0.7-5.3-4.9-11.8-9.4-16.2l-0.1,0c-12.8-12.6-41.2-19.3-84.5-19.9c-29.3-0.3-64.5,2.3-101.6,7.4c-16.6-9.6-33.7-20-47.1-32.5c-36.1-33.7-66.3-80.5-85.1-132c1.2-4.8,2.3-9,3.3-13.4c0,0,20.3-115.6,15-154.6c-0.8-5.3-1.2-6.9-2.6-11.1l-1.8-4.5c-5.5-12.8-16.4-26.3-33.4-25.5l-10.2-0.3c-19,0-34.4,9.7-38.5,24.2C411.2,255.9,424,324,447,412.1l-5.9,14.4c-16.5,40.3-37.2,80.8-55.5,116.6l-2.4,4.6c-19.2,37.6-36.6,69.5-52.4,96.6l-16.3,8.6c-1.2,0.6-29.1,15.4-35.7,19.4c-55.6,33.3-92.5,71-98.6,100.9c-2,9.6-0.5,21.8,9.4,27.4l15.8,8c6.9,3.4,14.1,5.2,21.4,5.2c39.7,0,85.7-49.4,149-160c73.2-23.8,156.5-43.6,229.5-54.5c55.6,31.3,124.1,53.1,167.3,53.1c7.7,0,14.3-0.7,19.7-2.1c8.3-2.2,15.2-6.9,19.5-13.4C820.3,624.2,821.9,606.8,819.7,589z M218.2,783.7c7.2-19.8,35.8-58.9,78.2-93.5c2.6-2.2,9.2-8.3,15.2-14C267.3,746.8,237.7,774.8,218.2,783.7z M468.7,206.7c12.7,0,20,32.1,20.6,62.2c0.6,30.1-6.4,51.2-15.2,66.9c-7.2-23.1-10.7-59.6-10.7-83.5C463.4,252.3,462.9,206.7,468.7,206.7z M394,617.9L394,617.9c8.8-15.9,18.1-32.6,27.5-50.4c23-43.5,37.5-77.5,48.4-105.5c21.5,39.2,48.3,72.5,79.8,99.1c3.9,3.3,8.1,6.7,12.4,10C498.1,583.8,442.8,599.2,394,617.9z M797.8,614.3L797.8,614.3c-3.9,2.4-15.1,3.8-22.2,3.8c-23.2,0-51.9-10.6-92.1-27.8c15.5-1.1,29.6-1.7,42.3-1.7c23.3,0,30.2,0,52.9,5.7C801.5,600.1,801.8,611.8,797.8,614.3z" />
    </g>
  </svg>
);

export default IconReport;
